@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-thumbnails-container) {
  text-align: left !important;
}
:global(.image-gallery-thumbnails) {
  padding-top: 12px;
  padding-bottom: 12px;

  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  width: 100vw;
  overflow: auto;
  /* height:100vh; */
  @media (--viewportMedium) {
    width: calc(100vw - 48px);
  }
  @media (--viewportLarge) {
    width: unset;
  }
  @media (max-width: 767px) {
    width: 100% !important;
  }
}
:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 0px;

  text-align: left !important;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-color: #fc334d;
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--colorWhite);
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    border-radius: var(--borderRadiusMedium);
    border: 1px solid var(--colorGrey100);
  }
}

:global(.fullscreen) {
  background-color: var(--colorWhite) !important;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
    /* height:100vh; */
  }
  & :global(.image-gallery-slides) {
    min-height: 100vh !important;
  }
  & > div {
    & > div {
      & > div {
        & > div {
          & > div {
            & > div {
              & > div {
                height: 100% !important;
                & > img {
                  height: auto !important;
                  object-fit: contain !important;
                }
                & > div {
                  height: 100% !important;
                  & img {
                    height: auto !important;
                    object-fit: contain !important;
                    @media (max-width: 1199px) {
                      height: 100% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
:global(.image-gallery-slides) {
  height: 460px;
}
.root {
}

.itemWrapper,
.itemWrapperFullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.itemWrapper {
}

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
}

.itemWrapperFullscreen {
  height: calc(100vh - 130px);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
}

.item {
  /* max-width: 100%; */
  width: 100%;
  /* max-height: 100%; */
  object-fit: cover;
  height: 485px;
  /* @media (max-width: 575px) {
    height: auto !important;
  } */
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  /* max-width: 88px;
  max-height: 88px; */
  border-radius: var(--borderRadiusMedium);
  width: 88px;
  height: 66px;
  object-fit: cover;
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  /* height: 100%; */
  border: 0;
  padding: 0;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
}

.openFullscreen {
  /* Hide on mobile. Currently react-image-gallery fullscreen mode is broken on
     mobile, and mobile doesn't really benefit from fullscreen anyways. */
  /* display: none; */
  display: block;
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportLarge) {
    display: block;
  }
  @media (max-width: 767px) {
    bottom: 12px;
    right: 12px;
    padding: 8px 10px 6px 13px;
  }
}

.close {
  composes: marketplaceModalCloseStyles from global;

  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);
  background-color: rgb(255 255 255 / 20%) !important;
  color: #000 !important;
  &:enabled:hover,
  &:enabled:active {
    color: var(--colorBlack);
  }
  z-index: 1;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}
.viewImages {
  position: absolute;
  z-index: 1;
  /* color: white; */
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 15px 10px;
  background-color: var(--colorWhite);
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadius);
  cursor: pointer;
  transition: var(--transitionStyleButton);
  font-size: 13px;
  @media (max-width: 767px) {
    bottom: 10px;
    right: 11px;
  }
  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }
}
.modalScrollLayer {
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(1, 1, 1, 0.92); */
  background-color: var(--colorWhite);
}
.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0 24px 0 0;
    display: flex;
    position: relative;
  }
  /* & .ListingImageGallery{
    & > img{
      object-fit: contain !important;
  }
  } */
  & > div {
    & > div {
      & > div {
        border: none !important;

        & > div {
          & > div {
            & > div {
              & > div {
                & > div {
                  & > div {
                    & > img {
                      object-fit: contain !important;
                      background-color: transparent !important;
                      border: none;
                    }
                    & > div {
                      & > img {
                        object-fit: contain !important;
                        background-color: transparent !important;
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  /* & .content{
      width: 100%;
      display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  padding: 8px 2px;
} */

  @media (--viewportXLarge) {
    padding: 0;
  }
}
/* .imageInModal{
  padding:100px 0;
} */
.imageGalleryDiv {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /* background-color:var(--wardrobeColorTransBlack); */
  z-index: 1001;
}
